import {
  Filter,
  Header,
  Features,
  KnowUs,
  Universities,
  Clients,
  Contact,
  Blogs,
  Platforms,
  Experiences,
  News
} from '../../components';

const Home = () => {

  return (
    <div>
      <Header />
      <Filter />
      <Features />
      <KnowUs />
      <Blogs />
      <Universities />
      <Experiences />
      <News />
      <Clients />
      <Platforms />
      <Contact />
    </div>
  )
}

export default Home