import DataAccordion from '../../components/UIs/DataAccordion/DataAccordion';

import { useTranslation } from 'react-i18next';

import cls from './questions.module.scss';

const questions = [
  {
    id: 'one',
    label: 'ما هي منصق ليان !؟',
    answer: 'شركة رائدة في مجال الخدمات الطلابية والقبولات الجامعية .أنشئت شركة علمني شكرا في عام 2015 حيث تهدف إلي مساعدة الطلاب الأجانب للإلتحاق بالجامعات التركية وتقديم خصومات دراسية مميزة والمساعدة في اختيار التخصص المناسب .وتمتلك شركة علمني شكرا فريق احترافي يمد يديه لجميع الطلاب حيث لاتقتصر مهمته علي تقديم أوراق الطالب للجامعة وتوفير قبول جامعي له ،وإنما استيعاب احتياجات الطالب بالكامل بدءا من تقديم كافة الاستشارات الجامعية مرورا باستقبال المطار وتوفير سكن مناسب وإنهاء كافة إجراءات إقامته في تركيا '
  },
  {
    id: 'two',
    label: 'ما هي المستندات اللازمة من أجل طلب تصريح الإقامة ؟',
    answer: 'شركة رائدة في مجال الخدمات الطلابية والقبولات الجامعية .أنشئت شركة علمني شكرا في عام 2015 حيث تهدف إلي مساعدة الطلاب الأجانب للإلتحاق بالجامعات التركية وتقديم خصومات دراسية مميزة والمساعدة في اختيار التخصص المناسب .وتمتلك شركة علمني شكرا فريق احترافي يمد يديه لجميع الطلاب حيث لاتقتصر مهمته علي تقديم أوراق الطالب للجامعة وتوفير قبول جامعي له ،وإنما استيعاب احتياجات الطالب بالكامل بدءا من تقديم كافة الاستشارات الجامعية مرورا باستقبال المطار وتوفير سكن مناسب وإنهاء كافة إجراءات إقامته في تركيا '
  },
  {
    id: 'three',
    label: 'متى يمكنني التقديم للجامعات التركية من خلال علمني شكرا ؟',
    answer: 'شركة رائدة في مجال الخدمات الطلابية والقبولات الجامعية .أنشئت شركة علمني شكرا في عام 2015 حيث تهدف إلي مساعدة الطلاب الأجانب للإلتحاق بالجامعات التركية وتقديم خصومات دراسية مميزة والمساعدة في اختيار التخصص المناسب .وتمتلك شركة علمني شكرا فريق احترافي يمد يديه لجميع الطلاب حيث لاتقتصر مهمته علي تقديم أوراق الطالب للجامعة وتوفير قبول جامعي له ،وإنما استيعاب احتياجات الطالب بالكامل بدءا من تقديم كافة الاستشارات الجامعية مرورا باستقبال المطار وتوفير سكن مناسب وإنهاء كافة إجراءات إقامته في تركيا '
  },
  {
    id: 'four',
    label: 'لا يوجد معي شهادة لغة تركية ماذا أفعل ؟',
    answer: 'شركة رائدة في مجال الخدمات الطلابية والقبولات الجامعية .أنشئت شركة علمني شكرا في عام 2015 حيث تهدف إلي مساعدة الطلاب الأجانب للإلتحاق بالجامعات التركية وتقديم خصومات دراسية مميزة والمساعدة في اختيار التخصص المناسب .وتمتلك شركة علمني شكرا فريق احترافي يمد يديه لجميع الطلاب حيث لاتقتصر مهمته علي تقديم أوراق الطالب للجامعة وتوفير قبول جامعي له ،وإنما استيعاب احتياجات الطالب بالكامل بدءا من تقديم كافة الاستشارات الجامعية مرورا باستقبال المطار وتوفير سكن مناسب وإنهاء كافة إجراءات إقامته في تركيا '
  },
  {
    id: 'five',
    label: 'هل تقدم الجامعات برامج توجيه مفصلة عن الدراسة بها ؟',
    answer: 'شركة رائدة في مجال الخدمات الطلابية والقبولات الجامعية .أنشئت شركة علمني شكرا في عام 2015 حيث تهدف إلي مساعدة الطلاب الأجانب للإلتحاق بالجامعات التركية وتقديم خصومات دراسية مميزة والمساعدة في اختيار التخصص المناسب .وتمتلك شركة علمني شكرا فريق احترافي يمد يديه لجميع الطلاب حيث لاتقتصر مهمته علي تقديم أوراق الطالب للجامعة وتوفير قبول جامعي له ،وإنما استيعاب احتياجات الطالب بالكامل بدءا من تقديم كافة الاستشارات الجامعية مرورا باستقبال المطار وتوفير سكن مناسب وإنهاء كافة إجراءات إقامته في تركيا '
  },
  {
    id: 'six',
    label: 'ما الذي يجب علي القيام به من أجل تجميد القيد الجامعي ؟',
    answer: 'شركة رائدة في مجال الخدمات الطلابية والقبولات الجامعية .أنشئت شركة علمني شكرا في عام 2015 حيث تهدف إلي مساعدة الطلاب الأجانب للإلتحاق بالجامعات التركية وتقديم خصومات دراسية مميزة والمساعدة في اختيار التخصص المناسب .وتمتلك شركة علمني شكرا فريق احترافي يمد يديه لجميع الطلاب حيث لاتقتصر مهمته علي تقديم أوراق الطالب للجامعة وتوفير قبول جامعي له ،وإنما استيعاب احتياجات الطالب بالكامل بدءا من تقديم كافة الاستشارات الجامعية مرورا باستقبال المطار وتوفير سكن مناسب وإنهاء كافة إجراءات إقامته في تركيا '
  }
]

const Questions = () => {
  const { t } = useTranslation('common');

  return (
    <div className={cls.questions}>
      <div className='container'>
        <h1 className='label'>
          <img src="/assets/images/question.png" alt="titleImage" loading="lazy" />
          <p>{t('questions.title')}</p>
        </h1>
        <div className={`${cls.accordion} accordion`} id="accordionExample">
          {questions.map((question, idx) => (
            <div key={idx} className={cls.question}>
              <DataAccordion data={question} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Questions